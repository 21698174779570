import React, { useCallback } from "react"
import { Row, Col } from "react-bootstrap"

import SEO from "../components/seo"
import ContainerWrapper from "../components/container"
import PasswordResetComponent from "../components/partials/password-reset"
import Alert from "../components/alert"

const PasswordReset = ({ location }) => {
  const getQueryParams = useCallback(() => {
    try {
      const paramsBag = new URLSearchParams((location || {}).search)
      const params = {}
      for (const [k, v] of paramsBag.entries()) {
        params[k] = decodeURIComponent(v)
      }
      return params
    } catch (e) {
      console.error(e)
    }
  }, [location])

  const resetURL = (getQueryParams() || {}).resetURL

  return (
    <>
      <SEO title="Password Reset" keywords={[`mazrui`, `careers`]} />
      <section className="section-password-reset">
        <ContainerWrapper classNames="main" fluid>
          <ContainerWrapper className="password-reset">
            <h2 className="text-center mt-0 page-title">Password Reset</h2>
            <p className="text-center">
              <small id="password-help-text" className="text-muted small">
                Change your password
              </small>
            </p>
            {((location || {}).state || {}).msg && location.state.msg.length
              ? location.state.msg.map(m => (
                  <Alert type={m.type} message={m.body} title={m.title} />
                ))
              : null}
            <hr />
            <Row>
              <Col md={{ span: 6, offset: 3 }} sm={12}>
                <PasswordResetComponent
                  endpoint={resetURL}
                  redirectURL={`/login`}
                />
              </Col>
            </Row>
          </ContainerWrapper>
        </ContainerWrapper>
      </section>
    </>
  )
}

export default PasswordReset
