import React, { useState } from "react"
import Axios from "axios"
import { navigate } from "gatsby"
import { Form, Button } from "react-bootstrap"
import Alert from "../alert"

const PasswordReset = ({
  endpoint,
  redirectURL,
  onSucess,
  validate,
  location,
}) => {
  const [state, setState] = useState({
    submitting: false,
    status: null,
  })

  const handleServerResponse = (ok, msg, form) => {
    setState({
      submitting: false,
      status: { ok, msg },
    })

    if (ok) {
      form.reset()
    }
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    const formElement = e.target
    const formData = new FormData(formElement)

    let data = {}
    for (let [k, v] of formData.entries()) {
      data[k] = v
    }

    setState({ submitting: true })
    Axios({
      method: "post",
      url: endpoint,
      data: formData,
    })
      .then(({ data }) => {
        if (onSucess && onSucess instanceof Function) {
          onSucess(data)
        }

        if (redirectURL) {
          if (typeof window !== "undefined") {
            navigate(redirectURL, {
              redirectedFrom: "/password-reset",
              state: {
                msg: [
                  {
                    type: "success",
                    title: "Password has been reset Successfully!",
                    body: "Please login with your newly created password",
                  },
                ],
              },
            })
          }
        }

        handleServerResponse(
          true,
          "Password has successfully reset!",
          formElement
        )
      })
      .catch(({ response }) => {
        handleServerResponse(
          false,
          ((response || {}).data || {}).errors ||
            "Error in resetting your password",
          formElement
        )
      })
  }

  const getMessage = message => {
    if (typeof message === "string") {
      return <span>{message}</span>
    }
    if (typeof message === "object") {
      return (
        <ul>
          {Object.keys(message).map(m => (
            <li>
              <strong>{m}</strong>: <span>{message[m].map(t => t)}</span>
            </li>
          ))}
        </ul>
      )
    }

    return undefined
  }

  return (
    <>
      {((state || {}).status || {}).ok === false ? (
        <Alert
          type="danger"
          title="Password Reset Error"
          message={getMessage(((state || {}).status || {}).msg)}
        />
      ) : null}
      <Form onSubmit={handleOnSubmit}>
        <fieldset disabled={(state || {}).submitting === true}>
          <p className="text-uppercase"> Provide new password: </p>
          <Form.Group>
            <Form.Control
              name="email"
              type="email"
              placeholder="Email"
              required
              className="form-controls"
              size="lg"
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              name="password"
              type="password"
              placeholder="Password"
              required
              className="form-controls"
              size="lg"
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              name="password_confirmation"
              type="password"
              placeholder="Confirm Your Password"
              required
              className="form-controls"
              size="lg"
            />
          </Form.Group>
          <Button
            type="submit"
            varient="primary"
            className="btn-d-border"
            block
          >
            Change Password
          </Button>
          <hr />
          <Button
            variant="secondary"
            className="btn-d-border"
            block
            onClick={() => {
              if (typeof window !== "undefined") {
                navigate("/login", {
                  redirectedFrom: "/password-reset",
                })
              }
            }}
          >
            Login
          </Button>
          <Button
            variant="secondary"
            className="btn-d-border"
            block
            onClick={() => {
              if (typeof window !== "undefined") {
                navigate("/regsiter", {
                  redirectedFrom: "/password-reset",
                })
              }
            }}
          >
            Register
          </Button>
        </fieldset>
      </Form>
    </>
  )
}

export default PasswordReset
